//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: [
    'content'
  ],  
  methods: {
    scrubLink(link) {
      return link.replace('pages/', '')
    },
  }
}
